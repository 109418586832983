import { FirebaseLesson, LocalizedFile, LocalizedString } from 'mosabi-data-access';
import React from 'react';
import { RouteComponentProps } from 'react-router';
import { DatabaseContextProps, withDatabase } from '../dataContext';
import { Loading } from './Loading';
import { NotFound } from './NotFound';
import { Grid, WithStyles, createStyles, withStyles, InputLabel, Card, CardHeader, CardMedia, CardContent, CardActions, Typography, Button } from "@material-ui/core";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import ReactPlayer from 'react-player';
import firebase from 'firebase';

const styles = (theme: any) => createStyles({
  quizSection: {
      padding: theme.spacing(3),
      margin: theme.spacing(3)
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  slide: {
      width: "500px"
  },
  cardActions: {
      justifyContent: "center"
  }
});

type Props = RouteComponentProps<{ lessonId: string, userIdOrPhoneNumber: string, langCode: string, apiKey: string }>
  & DatabaseContextProps & WithStyles<typeof styles>;

interface State {
  lesson: FirebaseLesson | undefined;
  isLoading: boolean;
  url: string | undefined;
  activeSlideIndex: number;
}

class EmbedView extends React.Component<Props, State>{
  constructor(props: Props) {
    super(props);

    this.state = {
      lesson: undefined,
      isLoading: true,
      url: undefined,
      activeSlideIndex: 0
    };
  }

  async componentDidMount() {
    await this.fetchData();
    document.title = "Mosabi - Embedded Content";
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />;
    }

    if (!this.state.lesson) {
      return <NotFound />;
    }

    return this.renderByType();
  }

  async fetchData() {
    this.setState({ isLoading: true });
    const auth = await firebase.auth().signInAnonymously();
    const verifyRequest = await this.props.databaseService.verifyAPIKey(this.props.match.params.apiKey);

    if(!verifyRequest){
      this.setState({ isLoading: false });
      console.log("API Key invalid.")
      return;
    }

    let lesson: FirebaseLesson | undefined;
    let url: string | undefined;

    const lessons = await this.props.databaseService.getLeessonsByIds([this.props.match.params.lessonId]);
    if (lessons && lessons.length === 1) {
      lesson = lessons[0];
    }

    if (lesson) {
      switch (lesson.type) {
        case "video":
          if (lesson.video) {
            const langVideo = lesson.video[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedFile];
            if (langVideo) {
              url = await this.props.databaseService.getDownloadUrl(langVideo.name);
            }
          }
          break;
        case "webview":
          if (lesson.webPage) {
            const langWebPage = lesson.webPage[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedFile];
            if (langWebPage) {
              url = await this.props.databaseService.getDownloadUrl(langWebPage.name);
            }
          }
          break;

        case "audio":
          if (lesson.audio && lesson.audio.audio) {
            const langAudio = lesson.audio.audio[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedFile];
            if (langAudio) {
              url = await this.props.databaseService.getDownloadUrl(langAudio.name);
            }
          }
          break;
      }
    }

    this.setState({
      isLoading: false,
      lesson: lesson,
      url: url
    });
  }

  renderByType() {
    if (!this.state.lesson) return;

    switch (this.state.lesson.type) {
      case "video":
        return this.renderVideo();
      case "quiz":
        return (<p>Quiz</p>);
      case "webview":
        return this.renderWebView();
      case "slides":
        return this.renderSlides();
      case "audio":
        return this.renderAudio();
      case "text":
        return this.renderTextSlides();
    }
  }

  renderVideo() {
    if (this.state.url) {
      return (
        <ReactPlayer
          url={this.state.url}
          controls={true}
          width={700} />
      );
    }
    else
      return (<span>Video missing...</span>);
  }

  renderWebView() {
    if (this.state.url) {
      return (<iframe width="700" height="900" src={this.state.url}></iframe>)
    }
    else
      return (<span>Web page missing...</span>);
  }

  private renderAudio() {
    if (!this.state.lesson) return;

    const audio = this.state.lesson.audio;
    if (!audio) {
      return undefined;
    }
    return (<>
      {audio.picture ?
        <div><img width={400} src={`https://storage.googleapis.com/mosabi-app.appspot.com/${audio.picture.name}`} /></div>
        : undefined}

      {this.state.url ?
        <div><ReactPlayer
          url={this.state.url}
          controls={true}
          height={50} /> </div>
        : undefined
      }
    </>);
  }

  private renderSlides() {
    if (!this.state.lesson) return;

    const slides = this.state.lesson.slides;
    const { classes } = this.props;
    if (!slides) {
      return undefined;
    }

    const slideList = slides.slides;
    return (<Grid container spacing={2}>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        {slideList.map((slide, i) => {
          const slideImg = slide.image ? slide.image[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedFile] : undefined;
          const slideText = slide.text ? slide.text[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedString] : undefined;
          
          return (
            <Card elevation={5} className={classes.slide} key={`slideEditor${i}`} style={{ display: this.state.activeSlideIndex === i ? "inherit" : "none" }}>
              <CardHeader
                title={`Slide ${i + 1} of ${slideList.length}`}
              />
              <CardMedia
                className={classes.media}
                image={slideImg ? `https://storage.googleapis.com/mosabi-app.appspot.com/${slideImg.name}` : undefined}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {slideText || "(none)"}
                </Typography>
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  color="primary"
                  startIcon={<ArrowLeft />}
                  disabled={i === 0}
                  onClick={() => this.setState({ activeSlideIndex: this.state.activeSlideIndex - 1 })}
                >
                  Prev
                </Button>
                <Button
                  color="primary"
                  endIcon={<ArrowRight />}
                  disabled={i === slideList.length - 1}
                  onClick={() => this.setState({ activeSlideIndex: this.state.activeSlideIndex + 1 })}
                >
                  Next
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>);
  }

  private renderTextSlides() {
    if(!this.state.lesson) return;

    const slides = this.state.lesson.text;
    const { classes } = this.props;
    if (!slides) {
      return undefined;
    }

    const slideList = slides.slides;
    return (<Grid container spacing={2}>
      <Grid item xs={3}></Grid>
      <Grid item xs={6}>
        {slideList.map((slide, i) => {
          const slideText = slide.text[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedString] || "";
          const slideAudio = slide.audio && slide.audio[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedFile] ? slide.audio[(this.props.match.params.langCode ? this.props.match.params.langCode.toUpperCase() : "EN") as keyof LocalizedFile] : undefined;
          return (
            <Card elevation={5} className={classes.slide} key={`slideEditor${i}`} style={{ display: this.state.activeSlideIndex === i ? "inherit" : "none" }}>
              <CardHeader
                title={`Slide ${i + 1} of ${slideList.length}`}
              />
              <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                  {slideText || "(none)"}
                </Typography>
                {slideAudio ?
                  <ReactPlayer
                    url={`https://storage.googleapis.com/mosabi-app.appspot.com/${slideAudio.name}`}
                    controls={true}
                    width={400}
                    height={50} />
                  : undefined}
              </CardContent>
              <CardActions className={classes.cardActions}>
                <Button
                  color="primary"
                  startIcon={<ArrowLeft />}
                  disabled={i === 0}
                  onClick={() => this.setState({ activeSlideIndex: this.state.activeSlideIndex - 1 })}
                >
                  Prev
                </Button>
                <Button
                  color="primary"
                  endIcon={<ArrowRight />}
                  disabled={i === slideList.length - 1}
                  onClick={() => this.setState({ activeSlideIndex: this.state.activeSlideIndex + 1 })}
                >
                  Next
                </Button>
              </CardActions>
            </Card>
          );
        })}
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>);
  }


}

export const Embed = withDatabase(withStyles(styles)(EmbedView));