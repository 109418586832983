import { FirebaseWebService } from 'mosabi-web-sdk';
import React from 'react';

const IsMockEnabled: boolean = false;
const DatabaseContext = IsMockEnabled ?
   React.createContext<FirebaseWebService|undefined>(undefined) :
   React.createContext<FirebaseWebService|undefined>(undefined);

export interface DatabaseContextProps{
  databaseService: FirebaseWebService;
}

export const withDatabase = (Component:any) => (props:any) => (
  <DatabaseContext.Consumer>
    {databaseService => <Component {...props} databaseService={databaseService} />}
  </DatabaseContext.Consumer>
);

export const useDatabaseContext = (): FirebaseWebService => {
  var context = React.useContext(DatabaseContext);
  if (context === undefined) {
    throw new Error("useDatabaseContext must be used within a DatabaseProvider");
  }
  return context;
}

export default DatabaseContext;