import React from 'react';
import { BrowserRouter, Route, Switch, useHistory } from 'react-router-dom';
import { Embed } from './components/Embed';
import { NotFound } from './components/NotFound';

export const App = () => {
  var history = useHistory();
  return (
    <BrowserRouter>
      <Switch>
        <Route
          path="/embed/:lessonId/:userIdOrPhoneNumber/:langCode/:apiKey"
          component={Embed}
        />
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
  );
};
